// Import necessary modules
import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import SoftBox from "components/SoftBox";
import { Box, Card, Chip, Grid, Link, Typography } from "@mui/material";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";
import SoftButton from "components/SoftButton";
import { getProductionDemand } from "../../../redux/slices/production";
import { dispatch, useSelector } from "../../../redux/store";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
// Create your functional component
const MyGrid = () => {
  const [category, setCategory] = useState("all");
  const [date, setDate] = useState(null);
  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated
  useEffect(() => {
    // Call getProductionDemand instead of getDemandDetailed
    dispatch(getProductionDemand(category));
    const getDate = async () => {
      try {
        const res = await axios.get("https://oushadhi-api-staging.trigeminal-ai.com/date");
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);
  const { production } = useSelector((state) => state.production);

  // Define column definitions
  const columnDefs = [
    { headerName: "Sl No.", field: "sl_no", width: "100px", pinned: "left" },
    {
      headerName: "Product Code",
      field: "product_code",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "150px",
    },
    { headerName: "Product", field: "product", filter: true, width: "250px", pinned: "left" },
    { headerName: "Unit", field: "unit", filter: true, width: "100px", pinned: "left" },
    {
      headerName: "Demanded",
      children: [
        { headerName: "Govt", field: "DemandedGovt", filter: true, width: "100px" },
        { headerName: "DLR", field: "DemandedDLR", filter: true, width: "100px" },
        { headerName: "OTGT", field: "DemandedOTGT", filter: true, width: "100px" },
        // { headerName: "GP", field: "DemandedGP", filter: true, width: "100px" },
        { headerName: "Total", field: "DemandedTotal", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "Recieved",
      children: [
        { headerName: "Govt", field: "ReceivedGovt", filter: true, width: "100px" },
        { headerName: "DLR", field: "ReceivedDLR", filter: true, width: "100px" },
        { headerName: "OTGT", field: "ReceivedOTGT", filter: true, width: "100px" },
        // { headerName: "GP", field: "ReceivedGP", filter: true, width: "100px" },
        { headerName: "Total", field: "ReceivedTotal", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "Balance",
      children: [
        { headerName: "Govt", field: "BalanceGovt", filter: true, width: "100px" },
        { headerName: "DLR", field: "BalanceDLR", filter: true, width: "100px" },
        { headerName: "OTGT", field: "BalanceOTGT", filter: true, width: "100px" },
        // { headerName: "GP", field: "BalanceGP", filter: true, width: "100px" },
        { headerName: "Total", field: "BalanceTotal", filter: true, width: "100px" },
      ],
    },
  ];

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      Cumulative Demand{" "}
    </Typography>,
  ];
  // Sample row data

  // State for Ag-Grid configuration
  const [gridOptions, setGridOptions] = useState({
    defaultColDef: {
      flex: 1,
      minWidth: 80,
      resizable: true,
    },
    columnDefs: columnDefs,
    rowData: production,
  });
  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Lehyam",
    "Gritham",
    "Rasakriya",
    "KC",
    "KSC",
    "Choornam",
    "Liquid Kashayam",
    "Patent Items",
    "Gulika",
    "Bhasmam",
    "Siddha",
  ];
  // Render the component

  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Chip
                    size="small"
                    label={`Demand  updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  paginationPageSize={20}
                  rowData={production}
                  columnDefs={columnDefs}
                  pagination={true}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                gap: "10px",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default MyGrid;

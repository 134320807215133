/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import axios from "axios";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// DefaultNavbar dropdown menus
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import DocsMenu from "examples/Navbars/DefaultNavbar/Menus/DocsMenu";
import RMMenu from "./Menus/RmMenu";
import admin1 from "../../../Image/admin1.png";
import ADMIN from "../../../Image/ADMIN-01.png";
import CONTAINER from "../../../Image/container-01.png";
import RAWMATERIAL from "../../../Image/RAWMATRAIALS-01.png";
import PRODUCTION from "../../../Image/PRODUCTION-01.png";
import SALES from "../../../Image/MARKETING-01.png";
import icon11 from "../../../Image/icon11.png";
import icon12 from "../../../Image/icon12.png";
import ProductionMenu from "./Menus/ProductionMenu";
import UploadMenu from "./Menus/UploadMenu";
import ContainStore from "./Menus/ContainStoreMenu";
import MarketingMenu from "./Menus/MarketingMenu";
import { Avatar, Box, IconButton, Menu } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import { current } from "@reduxjs/toolkit";

function DefaultNavbar({ routes, transparent, light, action }) {
  const [uploadMenu, setUploadMenu] = useState(false);
  const [containStoreMenu, setContainStoreMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [productionMenu, setProductionMenu] = useState(false);
  const [rMMenu, setRmMenu] = useState(false);
  const [docsMenu, setDocsMenu] = useState(false);
  const [marketingMenu, setMarketingMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "https://oushadhi-api-staging.trigeminal-ai.com/date/notification"
        );
        setNotifications(response.data.message);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);
  //   const renderMenu = () => (
  //     <Menu
  //       anchorEl={openMenu}
  //       anchorReference={null}
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "left",
  //       }}
  //       open={Boolean(openMenu)}
  //       onClose={handleCloseMenu}
  //       sx={{ mt: 2 }}
  //     >
  //      <NotificationItem

  //   image={<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RoUK9oM2JlrDsIGdBp4NIyktSbqtoMA6ReLPYdNTXw&s" alt="person" />}
  //   title={["Sales data pulled from SAP"]}
  //   date="13 minutes ago"
  //   onClick={handleCloseMenu}
  // />
  // <NotificationItem
  //  image={<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RoUK9oM2JlrDsIGdBp4NIyktSbqtoMA6ReLPYdNTXw&s" alt="person" />}
  //   title={["Invoice data pulled from SAP"]}
  //   date="1 day ago"
  //   onClick={handleCloseMenu}
  // />
  // <NotificationItem
  //   image={<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RoUK9oM2JlrDsIGdBp4NIyktSbqtoMA6ReLPYdNTXw&s" alt="person" />}
  //   title={["MTS data pulled from SAP"]}
  //   date="2 day ago"
  //   onClick={handleCloseMenu}
  // />

  //     </Menu>
  //   );
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.map((notification, index) => (
        <NotificationItem
          key={index}
          image={
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RoUK9oM2JlrDsIGdBp4NIyktSbqtoMA6ReLPYdNTXw&s"
              alt="person"
            />
          }
          title={[notification]}
          // date="Today" // You can set the date as needed
          onClick={handleCloseMenu}
        />
      ))}
    </Menu>
  );

  const navigate = useNavigate();

  const openUploadMenu = ({ currentTarget }) => setUploadMenu(currentTarget);
  const closeUploadMenu = () => setUploadMenu(false);
  const openContainStoreMenu = ({ currentTarget }) => setContainStoreMenu(currentTarget);
  const closeContainStoreMenu = () => setContainStoreMenu(false);
  const openProductionMenu = ({ currentTarget }) => setProductionMenu(currentTarget);
  const closeProductionMenu = () => setProductionMenu(false);
  const openRMMenu = ({ currentTarget }) => setRmMenu(currentTarget);
  const closeRMMenu = () => setRmMenu(false);
  const openDocsMenu = ({ currentTarget }) => setDocsMenu(currentTarget);
  const closeDocsMenu = () => setDocsMenu(false);
  const openMarketingMenu = ({ currentTarget }) => setMarketingMenu(currentTarget);
  const closeMarketingMenu = ({ currentTarget }) => setMarketingMenu(false);

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const handleHome = () => {
    navigate("/dashboards/default");
  };
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();

    navigate("/authentication/sign-in/illustration");
  };
  const userRole = localStorage.getItem("userRole");
  console.log("🚀 ~ DefaultNavbar ~ userRole:", userRole);
  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <SoftBox
        py={1}
        px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
        // my={2}
        // mx={1}
        width="calc(100% - 100px)"
        // width="100%"
        // width="1400px"
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <SoftBox
          component={Link}
          to="/dashboards/default"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
        >
          <img style={{ height: "25px" }} src="/logo.png" />
        </SoftBox>
        <SoftBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          {userRole === "admin" && (
            <>
              {" "}
              <DefaultNavbarLink
                name="demand"
                openHandler={openDocsMenu}
                closeHandler={closeDocsMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="RM Store"
                openHandler={openRMMenu}
                closeHandler={closeRMMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="PM Store"
                openHandler={openContainStoreMenu}
                closeHandler={closeContainStoreMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="Production"
                openHandler={openProductionMenu}
                closeHandler={closeProductionMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="Marketing"
                openHandler={openMarketingMenu}
                closeHandler={closeMarketingMenu}
                light={light}
              />
            </>
          )}
          {userRole === "marketing" && (
            <>
              {" "}
              <DefaultNavbarLink
                name="demand"
                openHandler={openDocsMenu}
                closeHandler={closeDocsMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="Marketing"
                openHandler={openMarketingMenu}
                closeHandler={closeMarketingMenu}
                light={light}
              />
            </>
          )}
          {userRole === "production" && (
            <>
              {" "}
              <DefaultNavbarLink
                name="demand"
                openHandler={openDocsMenu}
                closeHandler={closeDocsMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="Production"
                openHandler={openProductionMenu}
                closeHandler={closeProductionMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="Marketing"
                openHandler={openMarketingMenu}
                closeHandler={closeMarketingMenu}
                light={light}
              />
            </>
          )}
          {userRole === "raw_materials" && (
            <>
              {" "}
              <DefaultNavbarLink
                name="demand"
                openHandler={openDocsMenu}
                closeHandler={closeDocsMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="RM Store"
                openHandler={openRMMenu}
                closeHandler={closeRMMenu}
                light={light}
              />
            </>
          )}
          {userRole === "container" && (
            <>
              <DefaultNavbarLink
                name="demand"
                openHandler={openDocsMenu}
                closeHandler={closeDocsMenu}
                light={light}
              />
              <DefaultNavbarLink
                name="PM store"
                openHandler={openContainStoreMenu}
                closeHandler={closeContainStoreMenu}
                light={light}
              />
            </>
          )}
        </SoftBox>
        {userRole === "admin" && (
          <>
            <img
              src={ADMIN}
              alt="Admin"
              style={{
                cursor: "default",
                padding: {
                  xs: "8px 10px", // padding for extra small screens
                  sm: "8px 20px", // padding for small screens
                  md: "8px 25px", // padding for medium screens
                  lg: "8px 30px", // padding for large screens and above
                },
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                marginRight: {
                  xs: "10px", // margin for extra small screens
                  sm: "20px", // margin for small screens
                  md: "30px", // margin for medium screens
                  lg: "40px", // margin for large screens and above
                },
              }}
            />
          </>
        )}
        {userRole === "container" && (
          <>
            <img
              src={CONTAINER}
              alt="Admin"
              style={{
                cursor: "default",
                padding: {
                  xs: "8px 10px", // padding for extra small screens
                  sm: "8px 20px", // padding for small screens
                  md: "8px 25px", // padding for medium screens
                  lg: "8px 30px", // padding for large screens and above
                },
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                marginRight: {
                  xs: "10px", // margin for extra small screens
                  sm: "20px", // margin for small screens
                  md: "30px", // margin for medium screens
                  lg: "40px", // margin for large screens and above
                },
              }}
            />
          </>
        )}
        {userRole === "raw_materials" && (
          <>
            <img
              src={RAWMATERIAL}
              alt="Admin"
              style={{
                cursor: "default",
                padding: {
                  xs: "8px 10px", // padding for extra small screens
                  sm: "8px 20px", // padding for small screens
                  md: "8px 25px", // padding for medium screens
                  lg: "8px 30px", // padding for large screens and above
                },
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                marginRight: {
                  xs: "10px", // margin for extra small screens
                  sm: "20px", // margin for small screens
                  md: "30px", // margin for medium screens
                  lg: "40px", // margin for large screens and above
                },
              }}
            />
          </>
        )}
        {userRole === "marketing" && (
          <>
            <img
              src={SALES}
              alt="Admin"
              style={{
                cursor: "default",
                padding: {
                  xs: "8px 10px", // padding for extra small screens
                  sm: "8px 20px", // padding for small screens
                  md: "8px 25px", // padding for medium screens
                  lg: "8px 30px", // padding for large screens and above
                },
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                marginRight: {
                  xs: "10px", // margin for extra small screens
                  sm: "20px", // margin for small screens
                  md: "30px", // margin for medium screens
                  lg: "40px", // margin for large screens and above
                },
              }}
            />
          </>
        )}
        {userRole === "production" && (
          <>
            <img
              src={PRODUCTION}
              alt="Admin"
              style={{
                cursor: "default",
                padding: {
                  xs: "8px 10px", // padding for extra small screens
                  sm: "8px 20px", // padding for small screens
                  md: "8px 25px", // padding for medium screens
                  lg: "8px 30px", // padding for large screens and above
                },
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                marginRight: {
                  xs: "10px", // margin for extra small screens
                  sm: "20px", // margin for small screens
                  md: "30px", // margin for medium screens
                  lg: "40px", // margin for large screens and above
                },
              }}
            />
          </>
        )}

        <Box display="flex" alignItems="center" marginLeft={5}>
          <IconButton
            size="small"
            color="black"
            sx={{ marginRight: 2, fontSize: 30 }} // Adjust the margin value as needed
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOpenMenu}
          >
            <NotificationsIcon />
            {/* <img alt="Notifications" src={icon11} /> */}
          </IconButton>
          {renderMenu()}
          {action &&
            (action.type === "internal" ? (
              <>
                <IconButton color="black" onClick={(e) => handleLogout(e)} sx={{ p: 0 }}>
                  {/* <img alt="Remy Sharp" src={icon12} /> */}
                  <LogoutIcon />
                </IconButton>
              </>
            ) : (
              <SoftBox display={{ xs: "none", lg: "inline-block" }}>
                <IconButton color="black" onClick={(e) => handleLogout(e)} sx={{ p: 0 }}>
                  {/* <img alt="Remy Sharp" src={icon12} /> */}
                  <LogoutIcon />
                </IconButton>
              </SoftBox>
            ))}
        </Box>
        {/* {renderMenu()} */}
        <SoftBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </SoftBox>
      </SoftBox>
      <DocsMenu routes={routes} open={docsMenu} close={closeDocsMenu} />

      <UploadMenu routes={routes} open={uploadMenu} close={closeUploadMenu} />
      <ContainStore routes={routes} open={containStoreMenu} close={closeContainStoreMenu} />
      <ProductionMenu routes={routes} open={productionMenu} close={closeProductionMenu} />
      <RMMenu routes={routes} open={rMMenu} close={closeRMMenu} />
      <MarketingMenu routes={routes} open={marketingMenu} close={closeMarketingMenu} />

      {mobileView && (
        <DefaultNavbarMobile routes={routes} open={mobileNavbar} close={closeMobileNavbar} />
      )}
    </div>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;

import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DataTable from "examples/Tables/DataTable";
import page_Routes from "page_routes";
import React, { useEffect, useState } from "react";
import { getPreBudgetStock } from "../../../redux/slices/marketing";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import axios from "axios";

function DashboardBudgeting() {
  const dispatch = useDispatch(); // Redux dispatch function
  const [category, setCategory] = useState("all");
  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated
  console.log("api", category);

  useEffect(() => {
    console.log(category);
    dispatch(getPreBudgetStock(category)); // Call getContainStore1 action
    const getDate = async () => {
      try {
        const res = await axios.get("https://oushadhi-api-staging.trigeminal-ai.com/date");
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);
  const { marketing } = useSelector((state) => state.marketing);
  console.log("marketing", marketing);
  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "90px",
      pinned: "left",
    },
    {
      headerName: "Product Code",
      field: "product_code",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "145px",
    },
    {
      headerName: "Product Description",
      field: "product_description",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "190px",
    },
    {
      headerName: "Unit",
      field: "product_unit",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "110px",
    },
    {
      headerName: "Rate(INR)",
      field: "govt_rate",
      sortable: true,
      filter: true,
      width: "130px",
    },

    // { headerName: "April", field: "april_demand", sortable: true, filter: true, width: "100px" },
    // {
    //   headerName: "May",
    //   field: "may_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "June",
    //   field: "june_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    {
      headerName: "April",
      children: [
        { headerName: "Govt", field: "April_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "April_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "April_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_April", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "May",
      children: [
        { headerName: "Govt", field: "May_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "May_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "May_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_May", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "June",
      children: [
        { headerName: "Govt", field: "June_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "June_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "June_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_June", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "July",
      children: [
        { headerName: "Govt", field: "July_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "July_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "July_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_July", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "August",
      children: [
        { headerName: "Govt", field: "August_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "August_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "August_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_August", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "September",
      children: [
        { headerName: "Govt", field: "September_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "September_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "September_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_September", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "October",
      children: [
        { headerName: "Govt", field: "October_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "October_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "October_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_October", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "November",
      children: [
        { headerName: "Govt", field: "November_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "November_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "November_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_November", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "December",
      children: [
        { headerName: "Govt", field: "December_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "December_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "December_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_December", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "January",
      children: [
        { headerName: "Govt", field: "January_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "January_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "January_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_January", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "February",
      children: [
        { headerName: "Govt", field: "February_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "February_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "February_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_February", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "March",
      children: [
        { headerName: "Govt", field: "March_Govt", filter: true, width: "100px" },
        { headerName: "DLR", field: "March_DLR", filter: true, width: "100px" },
        { headerName: "GP", field: "March_GP", filter: true, width: "100px" },
        { headerName: "Total", field: "budget_total_March", filter: true, width: "100px" },
      ],
    },
    // { headerName: "July", field: "july_demand", sortable: true, filter: true, width: "120px" },
    // { headerName: "August", field: "august_demand", sortable: true, filter: true, width: "120px" },
    // {
    //   headerName: "September",
    //   field: "september_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "October",
    //   field: "october_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "November",
    //   field: "november_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "December",
    //   field: "december_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "January",
    //   field: "january_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // {
    //   headerName: "February",
    //   field: "february_demand",
    //   sortable: true,
    //   filter: true,
    //   width: "120px",
    // },
    // { headerName: "March", field: "march_demand", sortable: true, filter: true, width: "120px" },
    {
      headerName: "Total Qty",
      field: "total_demand",
      sortable: true,
      filter: true,
      width: "120px",
    },
    {
      headerName: "Total Value(INR)",
      field: "total_demand_price",
      sortable: true,
      filter: true,
      width: "160px",
    },
  ];

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      {/* Live Demand Batches{" "} */}
      Budget
    </Typography>,
  ];
  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Lehyam",
    "Gritham",
    "Rasakriya",
    "KC",
    "KSC",
    "Choornam",
    "Liquid Kashayam",
    "Patent Items",
    "Gulika",
    "Bhasmam",
    "Siddha",
  ];

  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Chip
                    size="small"
                    label={`Demand  updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  alwaysShowHorizontalScroll
                  // suppressBrowserResizeObserver
                  paginationPageSize={20}
                  rowData={marketing}
                  columnDefs={columnDefs}
                  pagination={true}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                gap: "10px",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default DashboardBudgeting;
